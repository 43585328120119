import React from "react"

const notFound = () => {
  return (
    <div>
      <h1>Oups</h1>
      <p>Il y a rien ici</p>
    </div>
  )
}

export default notFound
